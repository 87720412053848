import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'translationParams',
  pure: true,
  standalone: true,
})
export class TranslationParamsPipe implements PipeTransform {
  public constructor(private sanitizer: DomSanitizer) {}

  public transform(translation: string, labels: object, allowUnsafeHtml: boolean = false): string | SafeHtml {
    if (!translation) {
      return '';
    }

    const parsedTranslation = translation.replace(/\[(.*?)\]/g, (key) => {
      const parsedKey = key.replace('[', '').replace(']', '');

      if (!labels || labels[parsedKey] === undefined) {
        console.error('Missing translation parameter for ', parsedKey);
        return parsedKey;
      }

      return labels[parsedKey];
    });

    return allowUnsafeHtml ? this.sanitizer.bypassSecurityTrustHtml(parsedTranslation) : parsedTranslation;
  }
}
